var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('LoggerAssetHistoryStyled', [_c('div', [_c('h3', [_vm._v(_vm._s(_vm.title))])]), _vm.loggerRelations.length < 1 ? [_vm._v(" " + _vm._s(_vm.$t('messages.noData')) + " ")] : [_vm._l(_vm.loggerRelations, function (loggerRelation) {
    return [_c('HistoryStyled', {
      key: loggerRelation.id
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v("id: " + _vm._s(loggerRelation.id))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("fromTime: " + _vm._s(loggerRelation.fromTime))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("tillTime: " + _vm._s(loggerRelation.tillTime))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("comment: " + _vm._s(loggerRelation.comment))]), _c('h4', [_vm._v("Machine Details")]), _c('div', {
      staticClass: "label"
    }, [_vm._v("id: " + _vm._s(loggerRelation.machine.id))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("name: " + _vm._s(loggerRelation.machine.name))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("serialNumber: " + _vm._s(loggerRelation.machine.serialNumber))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("Manufacturer: " + _vm._s(loggerRelation.machine.manufacturer))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("ManufacturerCuid: " + _vm._s(loggerRelation.machine.manufacturerCuid))]), _c('h4', [_vm._v("Logger Details")]), _c('div', {
      staticClass: "label"
    }, [_vm._v("id: " + _vm._s(loggerRelation.logger.id))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("model: " + _vm._s(loggerRelation.logger.model.name))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("serialNumber: " + _vm._s(loggerRelation.logger.serialNumber))]), _c('div', {
      staticClass: "label"
    }, [_vm._v("password: " + _vm._s(loggerRelation.logger.password))])])];
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }