<template>
  <LoggerAssetHistoryStyled>
    <div>
      <h3>{{ title }}</h3>
    </div>
    <template v-if="loggerRelations.length < 1">
      {{ $t('messages.noData') }}
    </template>
    <template v-else>
      <template v-for="loggerRelation in loggerRelations">
        <HistoryStyled :key="loggerRelation.id">
          <div class="label">id: {{ loggerRelation.id }}</div>
          <div class="label">fromTime: {{ loggerRelation.fromTime }}</div>
          <div class="label">tillTime: {{ loggerRelation.tillTime }}</div>
          <div class="label">comment: {{ loggerRelation.comment }}</div>
          <h4>Machine Details</h4>
          <div class="label">id: {{ loggerRelation.machine.id }}</div>
          <div class="label">name: {{ loggerRelation.machine.name }}</div>
          <div class="label">serialNumber: {{ loggerRelation.machine.serialNumber }}</div>
          <div class="label">Manufacturer: {{ loggerRelation.machine.manufacturer }}</div>
          <div class="label">ManufacturerCuid: {{ loggerRelation.machine.manufacturerCuid }}</div>
          <h4>Logger Details</h4>
          <div class="label">id: {{ loggerRelation.logger.id }}</div>
          <div class="label">model: {{ loggerRelation.logger.model.name }}</div>
          <div class="label">serialNumber: {{ loggerRelation.logger.serialNumber }}</div>
          <div class="label">password: {{ loggerRelation.logger.password }}</div>
        </HistoryStyled>
      </template>
    </template>
  </LoggerAssetHistoryStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import LOGGER_ASSET_QUERY from '#/graphql/loggers/loggerAssetConnection.gql'

const LoggerAssetHistoryStyled = styled('div')`
  overflow-y: auto;
  padding: 1rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
`
const HistoryStyled = styled('div')`
  grid-area: asset-logger-list;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 1rem;
  border-bottom: ${p => `2px solid ${p.theme.colors.archonBlue}`};
  .label {
    padding: 5px;
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    assetID: {
      type: String,
      default: null,
      required: false,
    },
    loggerID: {
      type: String,
      default: null,
      required: false,
    },
  },
  components: {
    LoggerAssetHistoryStyled,
    HistoryStyled,
  },
  data() {
    return {
      loggerRelations: [],
      errors: [],
    }
  },
  computed: {
    loggerRelationWhereInput() {
      const where = {}
      if (this.assetID) {
        where.machine = {
          id: {
            in: [this.assetID],
          },
        }
      }
      if (this.loggerID) {
        where.loggerWhere = {
          id: {
            in: [this.loggerID],
          },
        }
      }
      return where
    },
  },

  apollo: {
    loggerRelations: {
      query: LOGGER_ASSET_QUERY,
      variables() {
        return {
          where: this.loggerRelationWhereInput,
        }
      },
      skip() {
        return !this.loggerRelationWhereInput
      },
    },
  },
}
</script>
