<template>
  <ModelEditor
    v-if="logger"
    :title="title"
    :model="logger"
    :errors="errors"
    :canSave="isDirty"
    :canCancel="isDirty"
    :loading="$apollo.loading"
    @save="save"
    @cancel="restore"
  >
    <EditLoggerStyled>
      <LoggerInfoStyled v-if="logger" class="logger">
        <AutoForm :model.sync="logger" :modelName="'Logger'" :filterFields="filterFields" :disabledFields="disabledFields" />
        <div class="type">
          <div class="type-label">Manufacturer</div>
          <Multiselect :options="organizations" :value="selectedManufacturer" :disabled="true" trackBy="id" label="name" />
        </div>
        <div class="type">
          <div class="type-label">
            {{ $tc('loggerModel', 1) }}
          </div>
          <Multiselect
            :options="loggerModels"
            :value="selectedLoggerModel"
            :allow-empty="false"
            open-direction="top"
            deselect-label=""
            trackBy="id"
            label="name"
            @select="setLoggerModel"
          />
        </div>
        <div class="type">
          <div class="type-label">Supplier</div>
          <Multiselect
            :options="organizations"
            :value="selectedSupplierModel"
            :allow-empty="true"
            open-direction="top"
            deselect-label=""
            trackBy="id"
            label="name"
            @select="setSupplierModel"
          />
        </div>
        <div class="type">
          <div class="type-label">Legal Owner *</div>
          <Multiselect
            :options="organizations"
            :value="selectedLegalOwner"
            :allow-empty="false"
            open-direction="top"
            deselect-label=""
            trackBy="id"
            label="name"
            @select="setLegalOwner"
          />
        </div>
        <div class="type">
          <div class="type-label">Status *</div>
          <Multiselect
            :options="hardwareStatuses"
            :value="selectedStatus"
            :allow-empty="false"
            open-direction="top"
            deselect-label=""
            trackBy="id"
            label="title"
            @select="setSelectedStatus"
          />
        </div>
      </LoggerInfoStyled>
      <LoggerAssetHistory class="asset-logger-list" :loggerID="$route.params.id" :title="'Asset History'" />
      <AssetLoggerManager class="logger-asset-manager" :mode="'logger'" :title="'Asset Logger Manager'" />
    </EditLoggerStyled>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import isEqual from 'lodash/isEqual'
import ModelEditor from '@/components/misc/ModelEditor'
import LoggerAssetHistory from '@/components/Loggers/LoggerAssetHistory'
import { AutoForm } from '@common/components'
import { FlashMessages } from '@common/singletons'
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { flexCenter } from '@styles/mixins'
import { AssetLoggerManager } from '@common/components'
import LOGGER_QUERY from '#/graphql/loggers/logger.gql'
import LOGGER_MODELS_QUERY from '#/graphql/loggerModels/models.gql'
import HARDWARE_STATUS_QUERY from '#/graphql/loggers/hardwareStatuses.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/organizations.gql'
import UPDATE_LOGGER_QUERY from '#/graphql/loggers/updateLogger.gql'

const LoggerInfoStyled = styled('div')`
  .type {
    margin-bottom: 10px;
    position: relative;
    max-width: 25rem;
    display: flex;
    align-items: left;
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    backdrop-filter: blur(7px);
    padding: 0.25rem;
    .type-label {
      ${flexCenter}
      min-width: 8rem;
      font-size: 16px;
      padding: 0.25rem;
      color: ${p => p.theme.colors.archonBlue};
      height: 2.75rem;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    }
  }
`
const EditLoggerStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  grid-template-rows: 55rem;
  grid-template-areas:
    'logger asset-logger-list'
    'logger-asset-manager logger-asset-manager';
`

export default {
  components: {
    LoggerInfoStyled,
    ModelEditor,
    AutoForm,
    Multiselect,
    EditLoggerStyled,
    LoggerAssetHistory,
    AssetLoggerManager,
  },
  data() {
    return {
      logger: null,
      loggerLocal: null,
      errors: [],
      loggerModels: [],
      organizations: [],
      disabledFields: ['customerId'],
      filterFields: [
        'supplierCuid',
        'ownerCuid',
        'owner',
        'manufacturer',
        'createdBy',
        'supplier',
        'manufacturerCuid',
        'updatedBy',
        'serialNumber',
      ],
      hardwareStatuses: [],
      data: {},
    }
  },
  computed: {
    title() {
      return `Logger ${this.logger?.id ?? ''}`
    },
    isDirty() {
      return !isEqual(this.logger, this.loggerLocal)
    },
    selectedManufacturer() {
      const id = this.logger?.manufacturerCuid
      const s = this.organizations.find(f => f.id === id)
      return s
    },
    selectedLoggerModel() {
      const id = this.logger?.model?.id
      const s = this.loggerModels.find(f => f.id === id)
      return s
    },
    selectedSupplierModel() {
      const id = this.logger?.supplierCuid
      const s = this.organizations.find(f => f.id === id)
      return s
    },
    selectedLegalOwner() {
      const id = this.logger?.ownerCuid
      const s = this.organizations.find(f => f.id === id)
      return s
    },
    selectedStatus() {
      const id = this.logger?.status?.id
      const s = this.hardwareStatuses.find(f => f.id === id)
      return s
    },
  },
  methods: {
    setLoggerModel(model) {
      this.data.modelId = model.id
      this.updateLogger(this.data)
    },
    setSupplierModel(model) {
      this.data.supplierCuid = model.id
      this.updateLogger(this.data)
    },
    setLegalOwner(model) {
      this.data.ownerCuid = model.id
      this.updateLogger(this.data)
    },
    setSelectedStatus(model) {
      this.data.statusId = model.id
      this.updateLogger(this.data)
    },
    async save() {
      if (!this.logger) {
        return
      }
      const logger = { ...this.logger }

      const data = {
        password: logger.password,
        notes: logger.notes,
        supplierProject: logger.supplierProject,
        brand: logger.brand,
        firmware: logger.firmware,
        constructionDate: logger.constructionDate,
      }
      this.updateLogger(data)
    },
    async updateLogger(data) {
      data.id = this.$route.params.id
      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_LOGGER_QUERY,
          variables: {
            data,
          },
        })
        this.loggerLocal = res?.data.updateLogger ?? null
        this.$apollo.queries.logger.refetch()
        FlashMessages.$emit('success', `Logger ${this.loggerLocal.serialNumber} saved successfully`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async restore() {
      this.logger = this.loggerLocal
    },
  },
  apollo: {
    loggerModels: {
      query: LOGGER_MODELS_QUERY,
    },
    hardwareStatuses: {
      query: HARDWARE_STATUS_QUERY,
    },
    organizations: {
      query: ORGANIZATIONS_QUERY,
    },
    logger: {
      query: LOGGER_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      manual: true,
      result({ data }) {
        this.logger = data?.logger
        this.loggerLocal = data?.logger
      },
    },
  },
}
</script>
