var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.logger ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.logger,
      "errors": _vm.errors,
      "canSave": _vm.isDirty,
      "canCancel": _vm.isDirty,
      "loading": _vm.$apollo.loading
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_c('EditLoggerStyled', [_vm.logger ? _c('LoggerInfoStyled', {
    staticClass: "logger"
  }, [_c('AutoForm', {
    attrs: {
      "model": _vm.logger,
      "modelName": 'Logger',
      "filterFields": _vm.filterFields,
      "disabledFields": _vm.disabledFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.logger = $event;
      }
    }
  }), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Manufacturer")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedManufacturer,
      "disabled": true,
      "trackBy": "id",
      "label": "name"
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(" " + _vm._s(_vm.$tc('loggerModel', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.loggerModels,
      "value": _vm.selectedLoggerModel,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setLoggerModel
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Supplier")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedSupplierModel,
      "allow-empty": true,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setSupplierModel
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Legal Owner *")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedLegalOwner,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setLegalOwner
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Status *")]), _c('Multiselect', {
    attrs: {
      "options": _vm.hardwareStatuses,
      "value": _vm.selectedStatus,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "title"
    },
    on: {
      "select": _vm.setSelectedStatus
    }
  })], 1)], 1) : _vm._e(), _c('LoggerAssetHistory', {
    staticClass: "asset-logger-list",
    attrs: {
      "loggerID": _vm.$route.params.id,
      "title": 'Asset History'
    }
  }), _c('AssetLoggerManager', {
    staticClass: "logger-asset-manager",
    attrs: {
      "mode": 'logger',
      "title": 'Asset Logger Manager'
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }