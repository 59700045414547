var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelEditorStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "main"
  }, [_vm._t("default"), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.loading ? _c('LoadingOverlayStyled', [_c('TalpaLoaderWrapper')], 1) : _vm._e()], 1)], 2), _c('div', {
    staticClass: "footer"
  }, [_c('button', {
    attrs: {
      "disabled": !_vm.canSave
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('save');
      }
    }
  }, [_c('CheckIcon'), _vm._v(" save")], 1), _c('button', {
    attrs: {
      "disabled": !_vm.canCancel
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_c('XIcon'), _vm._v(" reset")], 1), _vm.hasErrors ? _c('AlertTriangleIcon') : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }